import * as React from "react"
import { Helmet } from "react-helmet"

import Hero from "../../components/hero"
import PageHeader from "../../components/page-header"
import Card from "../../components/card"
import Footer from "../../components/footer"

import "../../scss/site.scss"
//import StickyCta from "../../components/sticky-cta"

const DemocraticConversations = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Demokratiska Samtal | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/demokratiska-samtal"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/demokratiska-samtal"
        ></meta>
        <meta property="og:title" content="Demokratiska Samtal"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--demokratiska-samtal"
        >
          <Hero title="Demokratiska samtal">
            <p>
              Vi människor har ofta olika åsikter om vad som är rätt, sant och
              viktigt. För att vi ska kunna fatta demokratiska beslut så måste
              vi prata med varandra och låta dessa åsikter mötas. Hur ska vi
              fatta rättvisa beslut? Vad behövs för att ett samtal ska bli bra?
            </p>
            <p>
              Vi rekommenderar att ni jobbar med övningen{" "}
              <em>Vad är rättvisa?</em> innan ni jobbar med övningen{" "}
              <em>Skapa demokratiska samtal</em>.
            </p>
          </Hero>
          <section className="c-card-grid">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <Card
                    to="vad-ar-rattvisa"
                    title="Vad är rättvisa?"
                    text="Demokrati ska skapa rättvisa. Men vad är egentligen en rättvis fördelning? Här kan man ha många olika åsikter."
                    datetime="PT60M"
                    estimate="Övning, ca 60 min"
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <Card
                    to="skapa-demokratiska-samtal"
                    title="Skapa demokratiska samtal"
                    text="I ett demokratiskt samtal ska olika uppfattningar kunna diskuteras. Det gäller i politiken men också i alla andra sammanhang. Vi behöver hjälpas åt för att få till stånd ett bra samtal."
                    datetime="PT40M"
                    estimate="Övning, ca 40 min"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default DemocraticConversations
